import React, { useState } from 'react';
import { Route, Switch, Redirect, NavLink } from 'react-router-dom';
import { AccountApp } from './accounts/App';
import { UsersApp } from './users/App';
import { bindActionCreators } from 'redux';
import { checkToken, logout } from './auth/actions';
import { connect, useDispatch, useSelector } from 'react-redux';
import { State } from '../core/reducers';
import { LoadingScreen } from '../components/LoadingScreen';
import {
	Menu,
	MenuItem,
	Theme,
	Drawer,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Divider,
	RadioGroup,
	FormControlLabel,
	Radio
} from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import makeStyles from '@mui/styles/makeStyles';
import AccountCircle from '@mui/icons-material/AccountCircle';
import SupervisedUser from '@mui/icons-material/SupervisedUserCircle';
import Timer from '@mui/icons-material/Timer';
import Dashboard from '@mui/icons-material/Dashboard';
import ToggleOn from '@mui/icons-material/ToggleOn';
import Storage from '@mui/icons-material/Storage';
import { Trans, useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { getQueuesCookie } from './queues/actions';
import { DashboardApp } from './dashboard/App';
import { authenticateForFeatureManagement } from './utils';
import { ScheduledTasksApp } from './scheduled-tasks/App';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import LogoutIcon from '@mui/icons-material/Logout';

const styles = (theme: Theme) =>
	createStyles({
		root: {
			width: '100%',
			height: '100%',
			display: 'flex'
		},
		topbarTitle: {
			flexGrow: 1,
			textTransform: 'none'
		},
		content: {
			display: 'flex',
			width: '100%',
			height: '100%'
		}
	});

const mapStateToProps = (state: State) => ({
	user: state.sysadmin.auth.user
});
const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			getUser: checkToken,
			logout,
			goToQueues: getQueuesCookie
		},
		dispatch
	);

interface GeneralProps
	extends ReturnType<typeof mapStateToProps>,
		ReturnType<typeof mapDispatchToProps>,
		WithStyles<typeof styles> {}

class General extends React.Component<GeneralProps> {
	componentDidMount() {
		if (!this.props.user) {
			this.props.getUser();
		}
	}
	render() {
		const { user, classes } = this.props;
		if (!user) {
			return <LoadingScreen />;
		}
		return (
			<div className={classes.root}>
				<Sidebar />
				<div className={classes.content}>
					<Switch>
						<Route path="/sysadmin/app/accounts" component={AccountApp} />
						<Route path="/sysadmin/app/users" component={UsersApp} />
						<Route path="/sysadmin/app/dashboard" component={DashboardApp} />
						{user?.permissions.includes('view_scheduled_tasks') && (
							<Route path="/sysadmin/app/scheduled-tasks" component={ScheduledTasksApp} />
						)}
						<Redirect to="/sysadmin/app/dashboard" />
					</Switch>
				</div>
			</div>
		);
	}
}

const wrapped = withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(General));
export { wrapped as General };

const useStyles = makeStyles((theme) => ({
	docked: {
		width: theme.spacing(32)
	},
	drawerPaper: {
		position: 'fixed',
		width: theme.spacing(32)
	},
	bottomList: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
		justifyContent: 'flex-end'
	},
	link: {
		color: 'inherit',
		textDecoration: 'none'
	},
	activeLink: {
		'& > *': {
			background: theme.palette.grey[200]
		}
	},
	icon: {
		width: theme.spacing(5)
	},
	logo: {
		width: 100,
		margin: '8px 0 0 16px'
	}
}));

function Sidebar() {
	const [profileAnchorElement, setProfileAnchorElement] = useState<HTMLElement>(null);
	const classes = useStyles({});
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const permissions = useSelector((state: State) => state.sysadmin.auth.user.permissions);
	return (
		<Drawer variant="permanent" classes={{ paper: classes.drawerPaper, docked: classes.docked }}>
			<img
				id="navigation_logo"
				className={classes.logo}
				src="./../../static/images/relay-logo.svg"
				alt="relay logo"
				width={100}
				height={39}
			/>
			<List component="nav">
				<NavLink
					to="/sysadmin/app/dashboard"
					activeClassName={classes.activeLink}
					className={classes.link}
				>
					<ListItem button>
						<ListItemIcon className={classes.icon}>
							<Dashboard />
						</ListItemIcon>
						<ListItemText primary={t('sysadmin:dashboard_header')} />
					</ListItem>
				</NavLink>
				<NavLink
					to="/sysadmin/app/accounts"
					activeClassName={classes.activeLink}
					className={classes.link}
				>
					<ListItem button>
						<ListItemIcon className={classes.icon}>
							<SupervisedUser />
						</ListItemIcon>
						<ListItemText primary={t('sysadmin:account_header')} />
					</ListItem>
				</NavLink>
				<NavLink
					to="/sysadmin/app/users"
					activeClassName={classes.activeLink}
					className={classes.link}
				>
					<ListItem button>
						<ListItemIcon className={classes.icon}>
							<EmojiPeopleIcon />
						</ListItemIcon>
						<ListItemText primary={t('sysadmin:users_header')} />
					</ListItem>
				</NavLink>
				{permissions?.includes('edit_feature_flags') && (
					<ListItem button onClick={() => authenticateForFeatureManagement()}>
						<ListItemIcon className={classes.icon}>
							<ToggleOn />
						</ListItemIcon>
						<ListItemText primary={t('sysadmin:features_header')} />
					</ListItem>
				)}
				{permissions?.includes('manipulate_queues') && (
					<ListItem button onClick={() => getQueuesCookie()}>
						<ListItemIcon className={classes.icon}>
							<Storage />
						</ListItemIcon>
						<ListItemText primary={t('sysadmin:queues_header')} />
					</ListItem>
				)}
				{permissions?.includes('view_scheduled_tasks') && (
					<NavLink
						to="/sysadmin/app/scheduled-tasks"
						activeClassName={classes.activeLink}
						className={classes.link}
					>
						<ListItem button>
							<ListItemIcon className={classes.icon}>
								<Timer />
							</ListItemIcon>
							<ListItemText primary={t('sysadmin:tasks_header')} />
						</ListItem>
					</NavLink>
				)}
			</List>
			<Divider />
			<List component="nav" className={classes.bottomList}>
				<ListItem button onClick={(event) => setProfileAnchorElement(event.currentTarget)}>
					<ListItemIcon>
						<AccountCircle />
					</ListItemIcon>
					<ListItemText primary={t('sysadmin:profile_header')} />
				</ListItem>
				<Menu
					id="menu-appbar"
					anchorEl={profileAnchorElement}
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'right'
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'left'
					}}
					open={Boolean(profileAnchorElement)}
					onClose={() => setProfileAnchorElement(null)}
				>
					<RadioGroup
						aria-label="language"
						name="language"
						value={i18n.language}
						onChange={(event) => i18n.changeLanguage(event.target.value)}
					>
						<MenuItem>
							<FormControlLabel value="en" control={<Radio />} label="English" />
						</MenuItem>

						<MenuItem>
							<FormControlLabel value="de" control={<Radio />} label="Deutsch" />
						</MenuItem>
					</RadioGroup>
					<Divider />
					<MenuItem
						onClick={() => dispatch(logout())}
						style={{ justifyContent: 'center', fontWeight: '550', gap: '10px' }}
					>
						<Trans i18nKey="general:log_out" />
						<LogoutIcon />
					</MenuItem>
				</Menu>
			</List>
		</Drawer>
	);
}
