import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../reducers';
import * as actions from '../actions';
// reuse DropzoneDialog as long as both usages are the same
import { DropzoneDialog } from '../../orders/single/DropzoneDialog';
import { LoadingScreen } from '../../../components/LoadingScreen';
import { Chat } from '../../../mui-custom/Chat';
import { useAccountDeactivatedCheck } from '../../../hooks/useAccountDeactivatedCheck';
import { CasaviTicketComment, UITicketComment, UITicketCommentAuthor } from '@api/v1';
import { TicketCommentMessage } from '../../../mui-custom/Chat/TicketCommentMessage';

export function CommentCompose({ sx = {} }) {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const { accountDeactivated } = useAccountDeactivatedCheck();

	const isAddingComment = useSelector(
		(state: State) => state.orders?.single.commentCompose.isAdding
	);
	const { ticketDetails: ticket, commentCompose } = useSelector((state: State) => state.tickets);
	const { text, files, fileDialogOpen } = commentCompose;

	const [ticketComments, setTicketComments] = useState<UITicketComment[]>(null);
	useEffect(() => {
		setTicketComments(
			ticket?.TicketComments?.map((comment): UITicketComment => {
				return {
					...comment,
					text: comment.textRendered,
					ticketId: comment.TicketId,
					createdAt: comment.createdAt,
					author: getCommentAuthor(comment),
					attachments: comment.Attachments.map((att) => {
						const {
							id,
							name,
							s3key: s3Key,
							temporaryThumbUrl: thumbnailS3Key,
							temporaryUrl: url,
							itemType,
							itemId
						} = att;
						return {
							id,
							name,
							s3Key,
							thumbnailS3Key,
							itemType,
							itemId,
							url
						};
					})
				};
			}) || []
		);
	}, [ticket]);

	const {
		changeCommentText: onTextChange,
		openCommentFileDialog: openDialog,
		closeCommentFileDialog: closeDialog,
		addCommentFile: onAddFile,
		changeCommentFile: onChangeFile,
		removeCommentFile: onRemoveFile,
		postComment: onCreate
	} = actions;
	return (
		<>
			<div id="comment-container">
				{ticketComments && (
					<Chat
						title={t('orders:comment_chat_title')}
						files={files}
						handleAttachmentClick={() => dispatch(openDialog())}
						text={text}
						handleChange={(e) => {
							dispatch(onTextChange(e.target.value));
						}}
						handleSend={() => dispatch(onCreate(text))}
						comments={ticketComments}
						sx={sx}
						disabled={accountDeactivated}
						Item={TicketCommentMessage}
					>
						{isAddingComment && <LoadingScreen />}
					</Chat>
				)}
			</div>
			<DropzoneDialog
				open={fileDialogOpen}
				onClose={() => dispatch(closeDialog())}
				title={t('general:attachments_title')}
				dropzoneProps={{
					text: t('general:attachments_upload'),
					files,
					onDrop: (file) => dispatch(onAddFile(file)),
					onChangeFile: (file, data) => dispatch(onChangeFile(file, data)),
					onRemove: (file) => dispatch(onRemoveFile(file)),
					casavi: true,
					casaviUploadFileType: 'tickets'
				}}
			/>
		</>
	);
}

function getCommentAuthor(comment: CasaviTicketComment): UITicketCommentAuthor {
	if (comment.Contact) {
		return {
			id: comment.Contact.id,
			name: `${comment.Contact.firstName} ${comment.Contact.lastName}`,
			initials: getAuthorInitials(comment.Contact.firstName, comment.Contact.lastName),
			type: comment.Contact.relayAccountId ? 'INTERNAL' : 'EXTERNAL'
		};
	}
	if (comment.Author) {
		if (comment.Author.Contacts?.length > 0) {
			const contact = comment.Author.Contacts[0];
			return {
				id: contact.id,
				name: `${contact.firstName} ${contact.lastName}`,
				initials: getAuthorInitials(contact.firstName, contact.lastName),
				type: contact.relayAccountId ? 'INTERNAL' : 'EXTERNAL'
			};
		}
		return {
			id: comment.Author.id,
			name: `${comment.Author.firstName} ${comment.Author.lastName}`,
			initials: getAuthorInitials(comment.Author.firstName, comment.Author.lastName),
			type: 'EXTERNAL'
		};
	}
	return {
		id: null,
		name: null,
		initials: '?',
		type: 'EXTERNAL'
	};
}

function getAuthorInitials(firstName?: string, lastName?: string): string {
	return `${firstName?.charAt(0) ?? ''}${lastName?.charAt(0) ?? ''}` || '?';
}
