import {
	Card,
	Fab,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Typography
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingScreen } from '../../components/LoadingScreen';
import { fetchInsurances, applySearch } from './actions';
import { InsuranceListEntry } from './ListEntry';
import { AddDialog } from './AddDialog';
import { UIInsurance } from '@api/v1';
import { State } from '../reducers';
import { SearchBar as InsuranceSearchBar } from '../../components/SearchBar';
import { NoResultsForSearchScreen } from '../../components/NoResultsForSearchScreen';
import ActionBar from '../../mui-custom/ActionBar/ActionBar';
import { useMobile } from '../../hooks/useBreakpoints';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
	fab: {
		position: 'sticky',
		left: '100%',
		bottom: theme.spacing(2),
		marginTop: theme.spacing(1),
		marginRight: theme.spacing(2),
		flexShrink: 0
	}
}));

const PageNav: React.FC<{ onClickAction: () => void; isMobile: boolean }> = ({
	onClickAction,
	isMobile
}) => {
	const { t } = useTranslation();
	return (
		<ActionBar
			primary={
				isMobile
					? null
					: {
							children: t('insurances:add_dialog_title'),
							onClick: onClickAction
					  }
			}
			sx={{ borderRadius: 0, borderBottomWidth: 1, flexShrink: 0 }}
		>
			<Typography variant="h6">{t('general:insurances')}</Typography>
		</ActionBar>
	);
};

export function InsurancesList(props: {}) {
	const isMobile = useMobile();
	const classes = useStyles(props);
	const [dialogOpen, setDialogOpen] = useState(false);
	const { data, isFetching, hasMore } = useSelector((state: State) => ({
		data: state.insurances?.list?.data,
		isFetching: state.insurances?.list?.metaData?.isFetching,
		hasMore: state.insurances?.list?.metaData?.hasMore
	}));
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const [pageIndex, setPageIndex] = useState(0);
	const PAGE_SIZE = 100;
	const [activeInsurance, setActiveInsurance] = useState(null);

	const [searchInput, setSearchInput] = useState('');

	useEffect(() => {
		dispatch(fetchInsurances(pageIndex, PAGE_SIZE, searchInput));
	}, [pageIndex]);

	// redirect to the previous page if there is no data for the current page (after delete)
	useEffect(() => {
		if (!data?.length && pageIndex > 0) setPageIndex(pageIndex - 1);
	}, [data]);

	const resetDialog = useCallback(() => {
		setDialogOpen(false);
		setActiveInsurance(null);
	}, []);

	function handlePageChange(event: React.MouseEvent<HTMLButtonElement>, page: number): void {
		setPageIndex(page);
	}

	const renderTableContent = () => {
		if (isFetching) return <LoadingScreen />;
		if (data?.length) {
			return (
				<TableContainer sx={{ overflow: 'auto', maxHeight: '100%' }} component={Card}>
					<Table stickyHeader>
						<TableHead sx={{ position: 'sticky', top: 0, zIndex: 1 }}>
							<TableRow>
								<TableCell>{t('insurances:table_header_external_id')}</TableCell>
								<TableCell>{t('insurances:table_header_status')}</TableCell>
								<TableCell>{t('insurances:table_header_customer')}</TableCell>
								<TableCell>{t('insurances:table_header_property')}</TableCell>
								<TableCell>{t('insurances:table_header_description')}</TableCell>
								<TableCell>{t('insurances:table_header_damage_type')}</TableCell>
								<TableCell>{t('insurances:table_header_damage_expiration_date')}</TableCell>
								<TableCell />
							</TableRow>
						</TableHead>
						<TableBody>
							{data.map((insurance, _index) => (
								<InsuranceListEntry
									insurance={insurance}
									key={insurance.id}
									onEditClick={(editInsurance: UIInsurance) => {
										setDialogOpen(true);
										setActiveInsurance(editInsurance);
									}}
								/>
							))}
						</TableBody>
					</Table>
					<TablePagination
						component="div"
						count={hasMore ? -1 : PAGE_SIZE * pageIndex + data.length}
						page={pageIndex}
						onPageChange={handlePageChange}
						rowsPerPage={PAGE_SIZE}
						rowsPerPageOptions={[]}
					/>
				</TableContainer>
			);
		}
		return (
			<NoResultsForSearchScreen
				message={
					searchInput
						? t('insurances:nothing_found_on_search')
						: t('insurances:nothing_available_for_this_account')
				}
			/>
		);
	};

	return (
		<Stack sx={{ overflowX: 'hidden', height: '100%', width: '100%' }}>
			<PageNav onClickAction={() => setDialogOpen(true)} isMobile={isMobile} />
			<Stack
				sx={{
					flexGrow: 1,
					overflow: 'hidden',
					m: isMobile ? 0 : 2,
					gap: 2
				}}
			>
				<InsuranceSearchBar
					searchInput={searchInput}
					setSearchInput={setSearchInput}
					applySearch={(searchTerm: string) => {
						setPageIndex(0);
						return dispatch(applySearch(pageIndex, PAGE_SIZE, searchTerm));
					}}
					placeholder={'insurances:search_placeholder'}
				/>
				{renderTableContent()}
			</Stack>
			{isMobile && (
				<Fab color="primary" onClick={() => setDialogOpen(true)} className={classes.fab}>
					<AddIcon />
				</Fab>
			)}
			<AddDialog
				open={dialogOpen}
				onClose={resetDialog}
				insuranceData={activeInsurance}
				onSave={resetDialog}
				onDelete={resetDialog}
				searchInput={searchInput}
				pageIndex={pageIndex}
				pageSize={PAGE_SIZE}
			/>
		</Stack>
	);
}
