import i18n from 'i18next';
import ICU from 'i18next-icu';
import { initReactI18next } from 'react-i18next';
import de from './de.json';
import en from './en.json';
import { assign } from 'lodash';
import deLocaleData from 'i18next-icu/locale-data/de';

i18n
	.use(
		new ICU({
			localeData: deLocaleData
		})
	)
	.use(initReactI18next)
	.init({
		fallbackLng: 'de',
		debug: true,
		interpolation: {
			escapeValue: false
		},
		resources: {
			de,
			en,
			keys: toDotNotation(assign({}, de, en), [])
		},
		react: {
			nsMode: 'default'
		}
	});

export { i18n };

function toDotNotation(dictionary, path: string[]) {
	const results = {};
	for (const key in dictionary) {
		if (typeof dictionary[key] === 'string') {
			results[key] = [].concat(path, key).join('.') as any;
		} else {
			results[key] = toDotNotation(dictionary[key], [].concat(path, key));
		}
	}
	return results;
}
