import React from 'react';
import { Route, useHistory, useLocation } from 'react-router-dom';
import { AddTicketPage } from './single/AddTicketPage';
import { AddCustomFieldsPage } from './single/AddCustomFieldsPage';
import { ListPage } from './list/ListPage';
import { Fab, Grid, Stack, Typography } from '@mui/material';
import { useMobile } from '../../hooks/useBreakpoints';
import { AccountSpecificFeature, useAccountSpecificFeature } from '../acl/features';
import { TicketDetailView } from './single/DetailView';
import ActionBar from '../../mui-custom/ActionBar/ActionBar';
import { ArrowBack } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import makeStyles from '@mui/styles/makeStyles';

enum PATH {
	LIST_PAGE = '/app/tickets',
	ADDING_PAGE = '/app/tickets/add',
	DETAIL_PAGE = '/app/tickets/detail'
}

const TITLE = {
	[PATH.LIST_PAGE]: 'tickets:filter_switch_label',
	[PATH.ADDING_PAGE]: 'tickets:create_new_ticket',
	[PATH.DETAIL_PAGE]: 'tickets:ticket_details_title'
};

const useStyles = makeStyles((theme) => ({
	link: {
		textDecoration: 'none'
	},
	fab: {
		position: 'sticky',
		left: '100%',
		bottom: theme.spacing(2),
		marginTop: theme.spacing(1),
		marginRight: theme.spacing(2),
		height: 48
	}
}));

export const TicketsApp = (props: {}) => {
	const isMobile = useMobile();
	const { t } = useTranslation();
	const history = useHistory();
	const classes = useStyles(props);

	const { pathname } = useLocation();
	const hasTicketsCreation = useAccountSpecificFeature(AccountSpecificFeature.TicketCreation);
	if (!hasTicketsCreation) return null;

	return (
		<Grid sx={{ flexDirection: 'column', width: 1 }}>
			<ActionBar
				alternate={
					pathname !== PATH.LIST_PAGE && {
						children: <ArrowBack />,
						onClick: () => history.push({ pathname: '/app/tickets' })
					}
				}
				primary={
					pathname !== PATH.ADDING_PAGE && !isMobile
						? {
								children: t(TITLE[PATH.ADDING_PAGE]),
								onClick: () => history.push({ pathname: '/app/tickets/add' })
						  }
						: null
				}
				sx={{ borderRadius: 0, borderBottomWidth: 1 }}
			>
				<Typography variant="h6">{t(TITLE[pathname] || TITLE[PATH.DETAIL_PAGE])}</Typography>
			</ActionBar>
			{/* End of PageNav */}

			<Grid
				sx={{
					display: 'grid',
					rowGap: 2.5,
					m: isMobile ? 0 : 2,
					/* 100% - Height of SubPAgeNav (ActionBar: 72px) - borderBottom (1px) - margin-bottom */
					height: `calc(100% - 72px - 1px - ${isMobile ? 0 : 16}px)`,
					overflow: 'auto'
				}}
			>
				<Stack>
					<Route exact path={PATH.LIST_PAGE} component={ListPage} />
					<Route exact path={PATH.ADDING_PAGE} component={AddTicketPage} />
					<Route
						exact={isMobile}
						path={`${PATH.ADDING_PAGE}/custom-fields`}
						component={AddCustomFieldsPage}
					/>
					<Route exact path={`${PATH.DETAIL_PAGE}/:id`} component={TicketDetailView} />
					{isMobile && pathname === PATH.LIST_PAGE && (
						<Fab
							color="primary"
							variant="extended"
							sx={{ borderRadius: 2, ml: 'auto' }}
							onClick={() => history.push({ pathname: '/app/tickets/add' })}
							className={classes.fab}
						>
							<AddIcon />
							{t('tickets:create_new_ticket_mobile')}
						</Fab>
					)}
				</Stack>
			</Grid>
		</Grid>
	);
};
