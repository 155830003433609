import React, { useEffect, useRef, useState } from 'react';
import { Alert, Paper, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Dropdown } from '../../../components/Dropdown';
import { useMobile } from '../../../hooks/useBreakpoints';
import { useDispatch } from 'react-redux';
import { loadCustomers } from '../../customers/actions';
import { initialValue, AddTicketBusiness } from './AddTicket.business';
import { AddCustomFieldsPage } from './AddCustomFieldsPage';

export const AddTicketPage = () => {
	const { t } = useTranslation();
	const isMobile = useMobile();
	const dispatch = useDispatch();
	const casaviIds = useRef({ casaviTenantId: 0, casaviContactId: 0 });

	const { accountId, propertyManagers, properties, ticketTypes } =
		AddTicketBusiness.useTicketData();

	useEffect(() => {
		if (!propertyManagers) dispatch(loadCustomers());
	}, []);

	const propertyManagerControl = useState(initialValue);
	const [propertyManager] = propertyManagerControl;

	const propertyControl = useState(initialValue);
	const [property, setProperty] = propertyControl;

	const ticketTypeControl = useState(initialValue);
	const [ticketType, setTicketType] = ticketTypeControl;

	const [showCustomFieldInput, setShowCustomFieldInput] = useState(false);
	useEffect(() => {
		if (propertyManager.name && property.name && ticketType.name) setShowCustomFieldInput(true);
	}, [propertyManager.name, property.name, ticketType.name]);
	useEffect(() => {
		setShowCustomFieldInput(false);
		setProperty(initialValue);
		setTicketType(initialValue);
	}, [propertyManager]);

	useEffect(() => {
		casaviIds.current = AddTicketBusiness.loadPropertiesAndTicketTypes(
			propertyManager,
			propertyManagers,
			accountId,
			dispatch,
			setProperty,
			setTicketType
		);
	}, [propertyManager?.name]);

	return (
		<Paper
			variant="outlined"
			sx={isMobile ? { justifyContent: 'space-between', height: '100%' } : {}}
			style={{ maxWidth: '50rem' }}
		>
			<Stack spacing={4} sx={{ m: 2, mb: 4 }}>
				<Dropdown
					label={t('tickets:property_manager')}
					values={propertyManagers}
					control={propertyManagerControl}
					filterFields={['casaviTenantId', 'casaviContactId']}
				/>
				{!!propertyManager.name && !properties.length && (
					<Alert severity="error">{t('tickets:no_property_error')}</Alert>
				)}
				<Dropdown
					label={t('tickets:property')}
					values={properties}
					control={propertyControl}
					disabled={!propertyManager.name || !ticketTypes.length}
					filterFields={['externalId']}
				/>
				{!!propertyManager.name && !ticketTypes.length && (
					<Alert severity="error">{t('tickets:no_ticket_type_error')}</Alert>
				)}
				<Dropdown
					label={t('tickets:ticket_type')}
					values={ticketTypes}
					control={ticketTypeControl}
					disabled={!propertyManager.name}
				/>
			</Stack>
			{showCustomFieldInput && (
				<AddCustomFieldsPage
					propertyManagerId={propertyManager.id}
					propertyExternalId={properties?.find((p) => p.id === property.id)?.externalId}
					ticketTypeId={ticketType.id}
					casaviTenantId={casaviIds.current?.casaviTenantId}
					casaviContactId={casaviIds.current?.casaviContactId}
				/>
			)}
		</Paper>
	);
};
