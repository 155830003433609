import { api } from '../../lib/api';
import { createAsyncConstants, createConstants } from '../../lib/createConstants';
import { UIInsurance, UpdateInsuranceBody, DamageType } from '@api/v1';
import { showNotification } from '../actions';
import { i18n } from '../../../app/i18n';
import { State } from '../reducers';

export const constants = createConstants(['SET_IS_LOADING', 'CLEAR'], {
	prefix: 'INSURANCES'
});

export const asyncConstants = createAsyncConstants(['CREATE', 'FETCH', 'FETCH_MORE'], {
	prefix: 'INSURANCES'
});

export const fetchInsurances =
	(pageIndex: number, pageSize: number, searchTerm?: string) => (dispatch) => {
		return dispatch({
			type: asyncConstants.FETCH.TYPE,
			payload: api.get('/api/v1/insurances', { params: { pageIndex, pageSize, searchTerm } })
		});
	};

export const applySearch =
	(pageIndex: number, pageSize: number, searchTerm: string = undefined) =>
	(dispatch) => {
		dispatch({
			type: constants.CLEAR
		});
		return dispatch(fetchInsurances(pageIndex, pageSize, searchTerm));
	};

export const createInsurance =
	(pageIndex: number, pageSize: number, searchTerm: string, insurance: UIInsurance) =>
	(dispatch, _getState: () => State) => {
		return dispatch({
			type: asyncConstants.CREATE,
			payload: api.post('/api/v1/insurances', transformInsuranceData(insurance))
		})
			.then(() => {
				dispatch(showNotification(i18n.t('insurances:success_message'), 'success'));
				dispatch(fetchInsurances(pageIndex, pageSize, searchTerm));
			})
			.catch((err) => {
				dispatch(showNotification(i18n.t('general:default_error'), 'error'));
				throw err;
			});
	};

export const updateInsurance =
	(pageIndex: number, pageSize: number, searchTerm: string, insurance: UIInsurance) =>
	(dispatch, _getState: () => State) => {
		return dispatch({
			type: asyncConstants.CREATE,
			payload: api.patch(`/api/v1/insurances/${insurance.id}`, transformInsuranceData(insurance))
		})
			.then(() => {
				dispatch(showNotification(i18n.t('insurances:success_message'), 'success'));
				dispatch(fetchInsurances(pageIndex, pageSize, searchTerm));
			})
			.catch((err) => {
				dispatch(showNotification(i18n.t('general:default_error'), 'error'));
				throw err;
			});
	};
export const deleteInsurance =
	(pageIndex: number, pageSize: number, searchTerm: string, insurance: UIInsurance) =>
	(dispatch, _getState: () => State) => {
		return dispatch({
			type: asyncConstants.CREATE,
			payload: api.delete(`/api/v1/insurances/${insurance.id}`)
		})
			.then(() => {
				dispatch(showNotification(i18n.t('insurances:success_message_delete'), 'success'));
				dispatch(fetchInsurances(pageIndex, pageSize, searchTerm));
			})
			.catch((err) => {
				dispatch(showNotification(i18n.t('general:default_error'), 'error'));
				throw err;
			});
	};

const transformInsuranceData = (insurance: UIInsurance): UpdateInsuranceBody => {
	return {
		status: insurance.status,
		description: insurance.description,
		externalId: insurance.externalId,
		provider: insurance.provider,
		expirationDate: insurance.expirationDate,
		damageType: (
			insurance.damageType as Array<{
				value: DamageType;
				label: string;
			}>
		)
			.map((damageType) => damageType.value)
			.join(','),
		properties: insurance.properties.map((property) => property.id),
		customerId: insurance.customer.id
	};
};
