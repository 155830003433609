import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
	Grid,
	Chip,
	Dialog,
	DialogTitle,
	DialogContent,
	TextField,
	DialogActions,
	Button,
	DialogContentText,
	Tooltip,
	Box,
	Card
} from '@mui/material';
import Check from '@mui/icons-material/Check';
import Clear from '@mui/icons-material/Clear';
import BlockIcon from '@mui/icons-material/Block';
import makeStyles from '@mui/styles/makeStyles';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingScreen } from '../../../components/LoadingScreen';
import { State } from '../../../core/reducers';
import {
	getUsers,
	loginAsUser,
	getAccount,
	getActiveRestrictionsCount,
	patchActiveRestrictionsCount,
	getCustomers,
	createToken
} from './actions';
import moment from 'moment-with-locales-es6';
import { CustomerEditDialog } from './CustomerEditDialog';
import { features } from '../../utils';
import { MergeAccountButton } from './MergeAccountButton';
import { ChargebeeSubscriptionStatus } from './../../../../server/chargebee/chargebee.enums';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		height: '100%',
		padding: theme.spacing(2),
		overflow: 'auto'
	},
	paper: {
		width: '100%',
		overflowX: 'auto',
		marginTop: theme.spacing(2)
	},
	margin: {
		marginLeft: theme.spacing(2)
	},
	restrictionsContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginBottom: theme.spacing(1)
	},
	clickableRow: {
		'&:hover': {
			backgroundColor: theme.palette.grey[200]
		},
		cursor: 'pointer'
	},
	accountDetails: {
		marginBottom: theme.spacing(2),
		backgroundColor: '#eeeeee',
		padding: '15px'
	},
	accountDetailsGrid: {
		display: 'inline-grid',
		gap: theme.spacing(0.65)
	},
	accountField: {
		fontWeight: '500',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	accountDetailsBox: {
		marginLeft: '10px',
		borderRadius: '100px',
		paddingInline: '7px',
		color: 'white'
	},

	accountDetailsBoxUnkown: {
		marginLeft: '10px',
		borderRadius: '100px',
		paddingInline: '7px',
		color: 'grey',
		backgroundColor: 'white'
	},
	marginBottom: {
		marginBottom: theme.spacing(1)
	}
}));

export function UserList(props: { match }) {
	const { usersList, account, activeRestrictions, customersList, permissions } = useSelector(
		(state: State) => ({
			usersList: state.sysadmin.users.usersList,
			account: state.sysadmin.users.account,
			activeRestrictions: state.sysadmin.users.activeRestrictions,
			customersList: state.sysadmin.users.customersList,
			permissions: state.sysadmin.auth.user.permissions
		})
	);
	const dispatch = useDispatch();
	const classes = useStyles(props);
	const accountId = props.match.params.accountId;
	React.useEffect(() => {
		dispatch(getUsers(Number(accountId)));
		dispatch(getAccount(accountId));
		dispatch(getActiveRestrictionsCount(accountId));
		dispatch(getCustomers(accountId));
	}, []);

	const [isEditDialogOpen, setIsEditDialogOpen] = React.useState(false);
	const [selectedCustomer, setselectedCustomer] = React.useState(null);

	const handleOnRowClick = (customer) => {
		setIsEditDialogOpen(true);
		setselectedCustomer(customer);
	};

	const handleCreateToken = (accountId) => {
		createToken(accountId, dispatch);
	};

	const { t } = useTranslation();
	const { match } = props;

	const createAccountStatusBadge = (status) => {
		const statusMap = {
			[ChargebeeSubscriptionStatus.ACTIVE]: {
				translation: t('sysadmin:account_subscription_status_active'),
				backgroundColor: 'success.main',
				className: classes.accountDetailsBox
			},
			[ChargebeeSubscriptionStatus.IN_TRIAL]: {
				translation: t('sysadmin:account_subscription_status_trial'),
				backgroundColor: 'warning.main',
				className: classes.accountDetailsBox
			},
			[ChargebeeSubscriptionStatus.CANCELLED]: {
				translation: t('sysadmin:account_subscription_status_cancelled'),
				backgroundColor: 'error.main',
				className: classes.accountDetailsBox
			}
		};

		const { translation, backgroundColor, className } = statusMap[status] || {
			translation: t('sysadmin:account_subscription_status_unknown'),
			backgroundColor: 'white',
			className: classes.accountDetailsBoxUnkown
		};

		return (
			<Box className={className} sx={{ backgroundColor }}>
				{translation}
			</Box>
		);
	};

	if (usersList && account && activeRestrictions) {
		return (
			<div className={classes.root}>
				<Grid container justifyContent="space-between">
					<Grid item className={classes.marginBottom}>
						{account.deactivatedAt ? (
							<Typography variant="h4" color="grey.500" className={classes.marginBottom}>
								<Tooltip title={t('sysadmin:account_deactivated')}>
									<span>
										{account.name}&nbsp;({Number(match.params.accountId)})&nbsp;
										<BlockIcon
											color="warning"
											sx={{
												verticalAlign: 'middle',
												height: '1.3em',
												width: '1.3em'
											}}
										/>
									</span>
								</Tooltip>
								<Chip
									variant="outlined"
									color={account.usage === 'prod' ? 'primary' : 'secondary'}
									label={account.usage}
									sx={{ verticalAlign: 'middle', marginLeft: 1 }}
								/>
							</Typography>
						) : (
							<Typography variant="h4" className={classes.marginBottom}>
								{account.name}&nbsp;({Number(match.params.accountId)})&nbsp;
								<Chip
									variant="outlined"
									color={account.usage === 'prod' ? 'primary' : 'secondary'}
									label={account.usage}
									sx={{ verticalAlign: 'middle', marginLeft: 1 }}
								/>
							</Typography>
						)}
						<Typography variant="body2">
							{t('sysadmin:account_created_at')}
							:&nbsp;{moment(account.createdAt).format('L')}
						</Typography>
						{account.deactivatedAt ? (
							<Typography color="warning.main" sx={{ fontWeight: '500' }}>
								{t('sysadmin:account_deactivated_at')}
								:&nbsp;{moment(account.deactivatedAt).format('L')}
							</Typography>
						) : null}
					</Grid>
					<Grid item>
						<Table size="small">
							<TableHead>
								<TableRow>
									<TableCell>{t('general:streetAddress')}</TableCell>
									<TableCell>{t('general:postal_code')}</TableCell>
									<TableCell>{t('general:locality')}</TableCell>
									<TableCell>{t('general:country')}</TableCell>
								</TableRow>
								<TableRow>
									<TableCell>{account.address?.streetAddress}</TableCell>
									<TableCell>{account.address?.postalCode}</TableCell>
									<TableCell>{account.address?.locality}</TableCell>
									<TableCell>{account.address?.country}</TableCell>
								</TableRow>
							</TableHead>
						</Table>
					</Grid>
				</Grid>
				<Card className={classes.accountDetails}>
					<Grid container gap={5}>
						<Grid item className={classes.accountDetailsGrid}>
							<Typography variant="body2" className={classes.accountField}>
								{t('sysadmin:account_users')}:
								<Box
									className={classes.accountDetailsBox}
									sx={{
										backgroundColor:
											activeRestrictions.activeUsers >= account.maxActiveUsers
												? 'error.main'
												: 'primary.main'
									}}
								>
									{`${activeRestrictions.activeUsers}/${account.maxActiveUsers}`}{' '}
								</Box>
							</Typography>
							<Typography variant="body2" className={classes.accountField}>
								{t('sysadmin:account_properties')}:
								<Box
									className={classes.accountDetailsBox}
									sx={{
										backgroundColor:
											activeRestrictions.activeProperties >= account.maxActiveProperties
												? 'error.main'
												: 'primary.main'
									}}
								>
									{`${activeRestrictions.activeProperties}/${account.maxActiveProperties}`}
								</Box>
							</Typography>
							<Typography variant="body2" className={classes.accountField}>
								{t('sysadmin:account_customers')}:
								<Box
									className={classes.accountDetailsBox}
									sx={{
										backgroundColor:
											account.maxActiveCustomers &&
											activeRestrictions.activeCustomers >= account.maxActiveCustomers
												? 'error.main'
												: 'primary.main'
									}}
								>
									{`${activeRestrictions.activeCustomers}/${account.maxActiveCustomers || '*'}`}
								</Box>
							</Typography>
						</Grid>
						<Grid item className={classes.accountDetailsGrid}>
							<Typography
								variant="body2"
								className={classes.accountField}
								sx={{ fontWeight: '500' }}
							>
								{t('sysadmin:account_plan')}:<Typography variant="body2">{account.plan}</Typography>
							</Typography>
							<Typography variant="body2" className={classes.accountField}>
								{t('sysadmin:account_active_plan_until')}:
								<Typography variant="body2" sx={{ marginLeft: 2 }}>
									{account.activePlanUntil ? moment(account.activePlanUntil).format('L') : ' -'}
								</Typography>
							</Typography>
							<Typography variant="body2" className={classes.accountField}>
								{t('sysadmin:account_subscription_status')}:
								{createAccountStatusBadge(account.subscriptionStatus)}
							</Typography>
						</Grid>
					</Grid>
				</Card>
				<Grid container gap={2}>
					<RestrictionDialog />
					{account.apiAccessToken ? (
						<TextField
							fullWidth
							label="Api Access Token"
							margin="normal"
							defaultValue={account.apiAccessToken}
							InputProps={{
								readOnly: true
							}}
						/>
					) : (
						<Button
							variant="outlined"
							color="primary"
							onClick={() => {
								handleCreateToken(account.id);
							}}
						>
							{t('sysadmin:create_api_token')}
						</Button>
					)}
					<div>
						<AutoAcceptCustomersButton account={account} />
					</div>
					<div>
						<AutoAcceptPropertiesButton account={account} />
					</div>
					<MergeAccountButton />
				</Grid>
				<Typography variant="h4" sx={{ mt: 2 }}>
					{t('sysadmin:account_users_headline')}
				</Typography>
				<Paper className={classes.paper}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>{t('sysadmin:user_id')}</TableCell>
								<TableCell>{t('general:last_name')}</TableCell>
								<TableCell>{t('general:first_name')}</TableCell>
								<TableCell>{t('general:email')}</TableCell>
								<TableCell>{t('general:telephone')}</TableCell>
								<TableCell>{t('general:roles')}</TableCell>
								<TableCell>{t('sysadmin:user_status_registered')}</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{usersList.map((user) => (
								<TableRow key={user.id}>
									<TableCell>{user.id}</TableCell>
									<TableCell>{user.lastName}</TableCell>
									<TableCell>{user.firstName}</TableCell>
									<TableCell>
										{permissions?.includes('login_as_user') ? (
											<a
												href="/"
												onClick={(e) => {
													e.preventDefault();
													loginAsUser(Number(match.params.accountId), user.id);
												}}
											>
												{user.email}
											</a>
										) : (
											<a>{user.email}</a>
										)}
									</TableCell>
									<TableCell>{user.telephone}</TableCell>
									<TableCell>{user.roles.join(', ')}</TableCell>
									<TableCell>{user.registered ? <Check /> : <Clear />}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</Paper>
				<Typography variant="h4" sx={{ mt: 2 }}>
					{t('sysadmin:account_customers_headline')}
				</Typography>
				<Paper className={classes.paper}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>{t('sysadmin:user_id')}</TableCell>
								<TableCell>{t('sysadmin:customer_name')}</TableCell>
								<TableCell>{t('sysadmin:relay_account_id')}</TableCell>
								<TableCell>{t('sysadmin:external_id')}</TableCell>
								<TableCell>{t('sysadmin:document_count')}</TableCell>
								<TableCell>{t('sysadmin:casavi_tenant_id')}</TableCell>
								<TableCell>{t('sysadmin:created_At')}</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{customersList
								? customersList.map((customer) => (
										<TableRow
											className={classes.clickableRow}
											key={customer.id}
											onClick={() => handleOnRowClick(customer)}
										>
											<TableCell>{customer.id}</TableCell>
											<TableCell>{customer.name}</TableCell>
											<TableCell>{customer.accountId}</TableCell>
											<TableCell>{customer.externalId}</TableCell>
											<TableCell>{customer.documents?.length}</TableCell>
											<TableCell>{customer.casaviTenantId}</TableCell>
											<TableCell>{customer.createdAt}</TableCell>
										</TableRow>
								  ))
								: null}
						</TableBody>
					</Table>
				</Paper>
				{isEditDialogOpen ? (
					<CustomerEditDialog
						open={isEditDialogOpen}
						customer={selectedCustomer}
						closeModal={() => setIsEditDialogOpen(false)}
					/>
				) : null}
			</div>
		);
	} else {
		return <LoadingScreen />;
	}
}

export function RestrictionDialog() {
	const [open, setOpen] = React.useState(false);
	const { t } = useTranslation();
	const { account, activeRestrictions, permissions } = useSelector((state: State) => ({
		account: state.sysadmin.users.account,
		activeRestrictions: state.sysadmin.users.activeRestrictions,
		permissions: state.sysadmin.auth.user.permissions
	}));
	const handleClickOpen = () => {
		setOpen(true);
	};
	const newValues = {
		maxActiveCustomers: account.maxActiveCustomers,
		maxActiveProperties: account.maxActiveProperties,
		maxActiveUsers: account.maxActiveUsers
	};

	const handleSave = () => {
		setOpen(false);
		if (hasChangedRestrictions({ account, activeRestrictions, newValues })) {
			patchActiveRestrictionsCount(account.id, newValues);
			window.location.reload();
		}
	};

	const handleClose = (props) => {
		setOpen(false);
	};

	return (
		<div>
			<Button
				disabled={!permissions?.includes('edit_account_restrictions')}
				variant="outlined"
				color="primary"
				onClick={handleClickOpen}
			>
				{t('sysadmin:edit_restrictions')}
			</Button>
			<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
				<DialogTitle id="form-dialog-title">{t('sysadmin:restrictions')}</DialogTitle>
				<DialogContent>
					<DialogContentText>{t('sysadmin:edit_restrictions_help')}</DialogContentText>
					<TextField
						autoFocus
						margin="dense"
						id="users"
						label={t('sysadmin:account_users')}
						defaultValue={account.maxActiveUsers}
						onChange={(event) => {
							newValues.maxActiveUsers = parseInt(event.target.value, 10);
						}}
						type="number"
						InputProps={{ inputProps: { min: activeRestrictions.activeUsers } }}
						fullWidth
						variant="standard"
					/>
					<TextField
						autoFocus
						margin="dense"
						id="properties"
						label={t('sysadmin:account_properties')}
						defaultValue={account.maxActiveProperties}
						onChange={(event) => {
							newValues.maxActiveProperties = parseInt(event.target.value, 10);
						}}
						type="number"
						InputProps={{
							inputProps: { min: activeRestrictions.activeProperties }
						}}
						fullWidth
						variant="standard"
					/>
					<TextField
						autoFocus
						margin="dense"
						id="customers"
						label={t('sysadmin:account_customers')}
						defaultValue={account.maxActiveCustomers}
						type="number"
						onChange={(event) => {
							newValues.maxActiveCustomers = parseInt(event.target.value, 10);
						}}
						InputProps={{
							inputProps: { min: activeRestrictions.activeCustomers }
						}}
						fullWidth
						variant="standard"
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						{t('sysadmin:restrictions_dialog_cancel')}
					</Button>
					<Button onClick={handleSave} color="primary">
						{t('sysadmin:restrictions_dialog_save')}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}

function hasChangedRestrictions(options): boolean {
	const { account, activeRestrictions, newValues } = options;
	if (
		account.maxActiveUsers === newValues.maxActiveUsers &&
		account.maxActiveCustomers === newValues.maxActiveCustomers &&
		account.maxActiveProperties === newValues.maxActiveProperties
	) {
		return false;
	}
	return !(
		newValues.maxActiveUsers < activeRestrictions.activeUsers ||
		activeRestrictions.maxActiveProperties < newValues.activeProperties ||
		(newValues.maxActiveCustomers !== null &&
			newValues.maxActiveCustomers < activeRestrictions.activeCustomers)
	);
}

export function AutoAcceptCustomersButton(props: { account }) {
	const { t } = useTranslation();

	React.useEffect(() => {
		getFeatureFlag(props.account.id);
	});

	const [hasAutoAcceptCustomersFeature, setHasAutoAcceptCustomersFeature] = React.useState(false);

	const getFeatureFlag = async (accountId) => {
		const featureCheck = await features.checkFeatureByAccountId(
			'has_auto_accept_customers_feature',
			props.account.id
		);
		setHasAutoAcceptCustomersFeature(featureCheck);
	};

	const toggleAutoAcceptCustomersFeature = async (accountId) => {
		if (hasAutoAcceptCustomersFeature) {
			await features.deleteFeatureByAccountId('has_auto_accept_customers_feature', accountId);
			setHasAutoAcceptCustomersFeature(false);
		}
		if (!hasAutoAcceptCustomersFeature) {
			await features.createFeatureByAccountId('has_auto_accept_customers_feature', accountId);
			setHasAutoAcceptCustomersFeature(true);
		}
	};

	return (
		<Button
			variant="outlined"
			color="primary"
			onClick={() => {
				toggleAutoAcceptCustomersFeature(props.account.id);
			}}
		>
			{t(
				`sysadmin:account_auto_accept_customers${
					hasAutoAcceptCustomersFeature ? '_disable' : '_enable'
				}`
			)}
		</Button>
	);
}

export function AutoAcceptPropertiesButton(props: { account }) {
	const { t } = useTranslation();

	React.useEffect(() => {
		getFeatureFlag(props.account.id);
	});

	const [hasAutoAcceptPropertiesFeature, setHasAutoAcceptPropertiesFeature] = React.useState(false);

	const getFeatureFlag = async (accountId) => {
		const featureCheck = await features.checkFeatureByAccountId(
			'has_auto_accept_properties_feature',
			props.account.id
		);
		setHasAutoAcceptPropertiesFeature(featureCheck);
	};

	const toggleAutoAcceptPropertiesFeature = async (accountId) => {
		if (hasAutoAcceptPropertiesFeature) {
			await features.deleteFeatureByAccountId('has_auto_accept_properties_feature', accountId);
			setHasAutoAcceptPropertiesFeature(false);
		}
		if (!hasAutoAcceptPropertiesFeature) {
			await features.createFeatureByAccountId('has_auto_accept_properties_feature', accountId);
			setHasAutoAcceptPropertiesFeature(true);
		}
	};

	return (
		<Button
			variant="outlined"
			color="primary"
			onClick={() => {
				toggleAutoAcceptPropertiesFeature(props.account.id);
			}}
		>
			{t(
				`sysadmin:account_auto_accept_properties${
					hasAutoAcceptPropertiesFeature ? '_disable' : '_enable'
				}`
			)}
		</Button>
	);
}
