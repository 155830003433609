import { UIInsurance } from '@api/v1';
import { combineReducers } from 'redux';
import { MetaList } from 'server/browser.types';
import { constants, asyncConstants } from './actions';
import { UILazyLoadingMetaData } from '../documents/reducers';

export interface InsurancesState {
	list: UIInsuranceList;
}

interface UIInsuranceList extends MetaList<UIInsurance, UILazyLoadingMetaData> {}

const initialState = {
	data: null,
	metaData: {
		lastId: null,
		hasMore: false,
		isFetching: true
	}
};

export const InsurancesReducers = combineReducers<InsurancesState>({
	list: (state = initialState, action) => {
		switch (action.type) {
			case asyncConstants.FETCH.FULFILLED:
				return action.payload;
			case constants.SET_IS_LOADING:
				return { ...state, metaData: { ...state.metaData, isFetching: true } };
			case constants.CLEAR:
				return initialState;
			case asyncConstants.FETCH_MORE.FULFILLED:
				return {
					...action.payload,
					data: [...state.data, ...action.payload.data]
				};
			default:
				return state;
		}
	}
});
